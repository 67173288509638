const Achievements = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">Achievements</div>
        <br />
        <h6>
        The cell has been able to develop and transfer the know how for functional quartz MEMS (DETF) quartz gyro chip structure and silicon microthurster (VLM), GTEM cell design, high frequency characterization and measurement (above 1 GHz) of materials, in house software for waveguide based circuits and engine modeling etc.
        
        </h6>
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Achievements;