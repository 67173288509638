const Facilities = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">Facilities</div>
        <br />
        <h6>
        During the last few years the cell has acquired some infrastructure and technical facilities in adittion to the support from Indian Institute of Technology, Kharagpur along with ISRO. Apart from the support and previleges received from these insitutues the cell is itself well equipped with facilities like
        <br/><br/>
<b>Specialized Library</b> having almost 1000 books on Space Technology, Electromagnetics, RF, Microwave and Millimeter Wave, Antenna , RADAR, MEMS (Micro Electrical and Mechanical Systems), Digital Signal Processing,  Optical Communication, Communication Engineering, Instrumentation and Control Engineering, Analog and Digital Electronics.
<br/><br/>
<b>Software Lab</b> maintains 18 high-end Personal Computers, 2 licences of CST Microwave Studio (one for Frequency domain, one for Transient & Eigenmode), MICIAN Microwave Wizard, IE3D, Fidelity, etc.
<br/><br/>
<b>Radio Frequency</b> Lab 2.7 GHz Spectrum Analyzer, 100MHz – 3/6 GHz Spectrum Analyzer
<br/><br/>
<b>Seminar Hall</b>
        
        </h6>
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Facilities;