import ima1 from "./assets/1.jpg"
import ima2 from "./assets/2.jpg"
import ima3 from "./assets/3.jpg"
import ima4 from "./assets/4.jpg"
import ima5 from "./assets/5.jpg"
import ima6 from "./assets/6.jpg"


const Home = () => {
  return (<div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-6">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Kalpana_Chawla_Space_Technology_Cell%2C_IIT_Kharagpur.jpg/1200px-Kalpana_Chawla_Space_Technology_Cell%2C_IIT_Kharagpur.jpg" width="300" height="400"  alt="First slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={ima1} width="300" height="400" alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={ima2} width="300" height="400" alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={ima3} width="300" height="400" alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="https://kgpchronicle.iitkgp.ac.in/wp-content/uploads/2019/08/Pic-3.jpg" width="300" height="400" alt="Third slide" />
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 newstyle">
      <h1 className = "center"><b>Kalpana Chawla Space Technology Cell, IIT Kharagpur</b></h1>
      <br/>
      <h7>The activities of Space Technology Cell was initiated in 1998 in Indian Institute of Technology, Kharagpur with an aim to work in the areas of MEMS, Communications and Cryogenics including Engine Modeling. Subsequently the areas of materials, control systems, micro propulsions and VLSI design were included. The communications area extended to the areas of RF design, Electromagnetics, EMI/EMC and ESD. Starting with faculty members of the Departments of Aerospace Engineering, Chemical Engineering, Electronics & Electrical Communication Engineering, Electrical Engineering, Computer Science Engineering, Cryogenic Engineering, Mechanical Engineering, Metallurgical & Materials Engineering, Rubber Technology Centre, Advanced Technology Centre, G. S Sanyal School of Telecommunication, Mining Engineering and School of Medical Science & Technology.</h7>
      </div>
    </div>
  </div>
  );
}

export default Home;