const Resarea = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">Research Areas</div>
        <br />
        <ol>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Liquid Combustion, Propulsion and Cryogenics :</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        To develop analytical and computational tools to support the liquid propulsion programmes of ISRO, particularly in relation to Cryogenic upper stage. To investigate possibilities of air breathing propulsion and associated technologies. To carry out experimental work on advanced devices used in propulsion systems.
        </h6>
        </li>
        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Space Communications and EMI/EMC: </h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        Design and development of communication subsystems including base band processing, signal processing, modulation, coding, equalization and other related issues leading to communication IP core.
        <br/><br/>
To develop numerical electromagnetic code for analysis and synthesis of antennas alongwith feeders and rf subsystems. The generalized code should also address issues related to ‘Multilayer Microwave Circuits’ and EMI prediction. The cell also intends to develop a code for prediction of ESD in both GEO & LEO satellite.
        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Micromachine Sensors:</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        To design and develop prototypes for micromachined silicon sensors and actuators (inertial sensors, pressure/flow sensors, microstructures, microrelays etc) for use in satellites and launch vehicles for future space technology applications. It will also be targeted to develop microsystems integrating sensors with electronics.
        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Control, Navigation and Guidance</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        
        </h6>
        </li>
        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Embedded Systems and IP-Cores:</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        Design and development of Communication IP - Core on FPGA, DSP and VLSI.
        </h6>
        </li>
        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Life Support Engineering :</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        The understanding of our body functions/the way various organs of living elements react with its surroundings is very complicated. It’s even less obvious to find a solution when something goes wrong. Since science is working in every aspect of our society people are trying to understand the basic mechanism of life with the advancement of science, engineering and technology. In this endeavor a wonderful world of life saving engineering, a new interdisciplinary research area is booming up with amalgamation of physical science & engineering and medical biotechnology to find out more about mechanical organs, safety-conscious engineering devices, artificial blood, remote control robotic surgery, etc. and thus makes a difference between living or dying. <br/>
Moreover, in a disaster like situation e.g., tsunami, earthquake, train/car accident hundreds of life require emergency medical treatment in multidimensional aspects. A normal flow of life for many is suddenly interrupted and requires immediate medical care to save the life. On the other side in a very specific position like high altitude/desert area military operation, astronaut in the space craft, etc the situation is very extreme. The present available technology is practically handicraft to combat the situation for saving a life.  <br/>
Life saving engineering will deal with the various aspects of these areas and come up with newer technology and devices through intensive research program in interdisciplinary approach.


        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Smart Materials & Novel Materials:</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        Conventional materials exhibit limitations of equilibrium thermodynamics and hence can perform under near - equilibrium conditions. Furthermore, equilibrium materials are mostly useful for a single purpose. In order to reduce the burden of materials and size of components, multi-functionality is an essential requirement from engineering materials, which can also withstand extreme service conditions in terms of temperature, pressure, environment etc. These classes of materials that can offer multi-functionality (ferro -magnetic and ferro - electric) necessitate development of meta stable or non-equilibrium microstructures and compositions. The proposed activities on smart and novel materials concern development of new class of materials having novelty in composition, microstructures and architecture (multi-layer, composite or hybrid structures).
        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Power Electronics :</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        To design and develop advanced technologies for compact an energy efficient power supply system with embedded controls for on-board applications.
        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Smart Materials & Novel Materials:</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        Realizing the potential demand for human resources required in Space Technology, it is planned to conduct courses for researchers, professions, academics, students and public.
        </h6>
        </li>

        <br/>
        <li style = {{color: "#1F6F9A", fontWeight: "bold"}}>
        <h5>Electronic Devices:</h5>
        <h6 style = {{color: "black", fontWeight: "400"}}>
        To design and develop specialised electronic devices to function as integrated on-board sensors, intelligent guidance motion cnotrol of satellites, launch vehicles and instrumentation systems.
        </h6>
        </li>
        </ol>
        
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Resarea;