const Terms_conditions = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">History</div>
        <br />
        <h6>
        A new era has begun in the history of IIT Kharagpur with the creation of Space Technology Cell. The faculties of this Institute have already been involved with the various laboratories of the Indian Space Research Organization (ISRO) in different areas of space research. A good number of research projects have been funded to this Institute by ISRO under RESPOND programme and through MOU’s with individual ISRO laboratories. In order to strengthen the relationship of   IIT   Kharagpur   with   ISRO   and   to   boost collaborative research programmes with ISRO laboratories, a Space Technology Cell has been set up in this Institute by ISRO in July 1998.
<br/>
<br/>
In 2004 the cell has been renamed as Kalpana Chawla Space Technology Cell (KCSTC) of IIT, Kharagpur. The new building of KCSTC was inaugurated on 17th November 2004 by Shri G. Madhavan Nair, Chairman, ISRO and Secretary, Department of Space, Government of India.
<br/>
<br/>
The Joint Policy Committee (JPC) govern the activities of Space Technology Cell, IIT Kharagpur and it consists of 6 members nominated by Director, IIT Kharagpur and 6 members nominated by Chairman, ISRO with Director, IIT Kharagpur as ex-officio Chairman.
        </h6>
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Terms_conditions;