import { renderMarkup } from "react-render-markup";

function reformatDate(dateStr)
{
  let dArr = dateStr.split("-");  // ex input "2010-01-18"
  return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0]; //ex out: "18/01/10"
}

const Print_Post = ({ post }) => {
    let post_date = reformatDate(post.date.toString().substr(0, 10));
    return ( 
        <div className = "Individual">
            <h5 className = "center">{ post.title }</h5>
            <br />
            <p> <b>Date of Announcement: { post_date } </b></p>
            <h6><b>Description: </b>{ post.heading }</h6>
            <h6><b>Associated Links (if any):  </b>{post.link}</h6><br/>
            { <h6>{ renderMarkup(post.content) }</h6> }
        </div>
     );
}
 
export default Print_Post;