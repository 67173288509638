import Gallery from "react-photo-gallery";
import ima1 from "./assets/1.jpg"
import ima2 from "./assets/2.jpg"
import ima3 from "./assets/3.jpg"
import ima4 from "./assets/4.jpg"
import ima5 from "./assets/5.jpg"
import ima6 from "./assets/6.jpg"
import ima7 from "./assets/7.jpg"


const photos = [
    {
        src: ima5,
        width: 3,
        height: 2
      },
    {
      src: ima1,
      width: 3,
      height: 2
    },
      {
        src: ima2,
        width: 3,
        height: 2
      },
  ];

  const photos2 = [

    {
      src: ima3,
      width: 3,
      height: 2
    },
      {
        src: ima4,
        width: 3,
        height: 2
      },
      {
        src: ima6,
        width: 3,
        height: 2
      },
  ];

  const photos3 = [
    {
      src: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Kalpana_Chawla_Space_Technology_Cell%2C_IIT_Kharagpur.jpg/1200px-Kalpana_Chawla_Space_Technology_Cell%2C_IIT_Kharagpur.jpg",
      width: 3,
      height: 2
    },

    {
      src: ima7,
      width: 3,
      height: 2
    },
      
    {
        src: "https://kgpchronicle.iitkgp.ac.in/wp-content/uploads/2019/08/Pic-3.jpg",
        width: 3,
        height: 2
    }
    ]

const Galleree = () => {
    return (
    <div>
        <h4 className="center bold">Image Gallery</h4>
        <br/>
        <h5 className = "center">National Symposium on Cryogenics and Superconductivity - 28</h5>
        <br/>
    <Gallery photos = {photos}/>
    <Gallery photos = {photos2}/>
    <br/>
    <h5 className = "center">Kalpana Chawla Space Technology Cell, IIT Kharagpur</h5>
    <br/>
    <Gallery photos = {photos3}/>
    <br/>
    </div>)
}
 
export default Galleree;