import { Link } from "react-router-dom";

const Footer = () => {
  const kcstc_token = localStorage.getItem('kcstc_token');
  const dash = (kcstc_token===null) ? {to : "/login"} : {to : "/dashboard"};
    return ( 
        <footer className="mainfooter center">  
  <div className="footer-middle">  
  <div className="container">  
    <div className="row">  
      <div className="col-md-3 col-sm-6">  
        <div className="footer-pad">  
          <h4 className="white"> Quick Links</h4>  
          <ul className="list-unstyled">  
            <li> <a href="#" className ="white1"> </a> </li>  
            <li> <Link to="/aboutus" className ="white1">About Us</Link></li>  
            <li> <Link to="/history" className ="white1"> History </Link></li>
            <li> <Link to="/vision" className ="white1"> Vision </Link></li>    
            <div class="dropdown">
            <li className="btn dropdown-toggle white1" style={{color: "white", cursor: "pointer", fontFamily: "Arial", fontSize: 14}} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              People
            </li>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" to="/people">KCSTC Members</Link>
              <Link className="dropdown-item" to="/jpcmembers">JPC Members</Link>
            </div>
            </div>
            <li> <Link to="/achievements" className ="white1"> Achievements </Link></li>    
            {/* <li> <Link to="/guests" className ="white1"> Eminent Guests </Link></li> */}
            <li> <Link to="/gallery" className ="white1"> Gallery </Link></li> 
            <li><Link className = "white1" {...dash}>{(kcstc_token===null)?"Admin Login":"Dashboard"}</Link></li>
          </ul>  
        </div>
        <br />
      </div>
      <div className="col-md-3 col-sm-6">  
        <div className="footer-pad">  
          <h4 className="white"> Important Links</h4>  
          <ul className="list-unstyled">  
            <li><a href="http://www.iitkgp.ac.in/" target = "_blank" className ="white1">IIT KGP Official site</a></li> 
            <li><a href="https://www.isro.gov.in/" target = "_blank" className ="white1">ISRO Official site</a></li>
            <br/>  
            <li style={{color: "white", fontFamily: "Arial", fontSize: 15}}>Student Activities:</li>
            <li><a href="https://spats.co.in/" target = "_blank" className ="white1">SPATS Website</a></li> 
            <li><a href="https://www.nssc.in/" target = "_blank" className ="white1">NSSC Website</a></li>
          </ul>  
        </div>  
      </div>  
      <div className="col-md-3">  
        <div className="footer-pad">  
          <h4 className="white">Contact Us </h4>  
          <ul className="list-unstyled">  
            <li> <p className ="white1">Kalpana Chawla Space Technology Cell,<br/>
                                                  Indian Institute of Technology<br/>
                                                  Kharagpur - 721 302
                                                  </p></li> 
            <li> <p className ="white1">Email ID : office.kcstc[AT]adm[DOT]iitkgp[DOT]ac[DOT]in</p></li>
            <li> <p className ="white1">Phone Number: +91 3222 282298</p></li>
            <li> <p className ="white1">Fax Number: + 91 3222 282299</p></li>
          </ul>  
        </div>  
      </div>  
        <div className="col-md-3">  
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.8908051321705!2d87.31125441495506!3d22.319969085313446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d441b052e25fd%3A0xde37185a24d4e7ac!2sKalpana%20Chawla%20Space%20Technology%20Cell!5e0!3m2!1sen!2sin!4v1669999892578!5m2!1sen!2sin" width="300" height="220" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>  
    </div> 
    <br /> 
    <div className="row">  
    <div className="col-md-12 copy">  
    <p className="text-center white2"> Designed & Developed by Kalpana Chawla Space Technology Cell - IIT Kharagpur </p>  
    </div>  
    </div>  
  </div>  
  </div>  
</footer>  
     );
}
 
export default Footer;
