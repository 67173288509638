import { renderMarkup } from "react-render-markup";

const Subject_indiv = ({course, class_id}) => {
    let class1;
    course.sub_course.forEach(cls => {
        if (cls._id == class_id) 
        {
             class1 = cls;
        }
    })

    return ( 
        <div className = "Individual">
            <div className = "center bold">Details of {class1.Class}</div>
            <br />
            <h6><b>Description: </b> {renderMarkup(class1.description)}</h6>
            <div className = "tablestyle">
            <table className = "center5">
            <tr>
            <th>Sl. No.</th>
            <th>Title</th>
            {course.course_name.includes("ISRO") && <th>Collaborating Centre</th>}
            {course.course_name.includes("Sponsored") && <th>Sponsor</th>}
            {course.course_name.includes("Consult") && <th>Consultant</th>}
            <th>Duration</th>
            {course.course_name.includes("ISRO") && <th>PI/Co-PI</th>}
            {course.course_name.includes("Sponsored") && <th>Consultant</th>}
            {course.course_name.includes("Consult") && <th>Consultant</th>}
            {course.course_name.includes("Courses") && <th>Coordinator</th>}
            </tr>
            {class1.subjects.map((tab, index) => (
                <tr>
                <td>{index+1}</td>
                <td>{tab.Title}</td>
                {course.course_name.includes("ISRO") && <td>{tab.Client}</td>}
                {course.course_name.includes("Sponsored") && <td>{tab.Client}</td>}
                {course.course_name.includes("Consult") && <td>{tab.Client}</td>}
                <td>{tab.Duration}</td>
                <td>{tab.Consultant}</td>
                </tr>
            ))}
             </table>
             </div>
        </div>

     );
}

export default Subject_indiv;