import Accordion from 'react-bootstrap/Accordion';
import jpcmem from './assets/jpc_isro.json';
import jpckgp from './assets/jpc_kgp.json';


const Jpcmembers = () => {
    return ( 
        <div className=" container">
        <div className = "containing3">
        <div className = "Individual">
            <div className = "center bold">JPC Members</div>
            <br />
            <h5 className = "center">JPC Members from ISRO</h5><br/>
            <div className = "tablestyle">
            <table className = "center5">
            <thead>
            <tr>
            <th>Sl. No.</th>
            <th>Name</th>
            <th>Designation</th>
            </tr>
            </thead>
            <tbody>
                {jpcmem.map((mem, index) => {
                    return(<tr>
                        <td>{index + 1}</td>
                        <td>{mem.name}</td>
                        <td>{mem.designation}</td>
                    </tr>);
                })}
            </tbody>
             </table>
             <br/>
             <br/>
             </div>

             <h5 className = "center">JPC Members from IIT Kharagpur</h5><br/>
            <div className = "tablestyle">
            <table className = "center5">
            <thead>
            <tr>
            <th>Sl. No.</th>
            <th>Name</th>
            <th>Designation</th>
            </tr>
            </thead>
            <tbody>
            {jpckgp.map((mem, index) => {
                    return(<tr>
                        <td>{index + 1}</td>
                        <td>{mem.name}</td>
                        <td>{mem.designation}</td>
                    </tr>);
            })}
            </tbody>
             </table>
            <br/>
             </div>
        </div> 
        <br/>
        <Accordion>
           <Accordion.Item eventKey="ev1234">
           <Accordion.Header><b>ISRO Centres Involved: </b></Accordion.Header>
           <Accordion.Body>
                <ul>
                    <li>ISRO Inertial Systems Unit (IISU), Thiruvananthapuram </li>
                    <li>ISRO Propulsion Complex (IPRC), Mahendragiri</li>
                    <li>LVPO Bangalore</li>
                    <li>Laboratory for Electro-Optics Systems (LEOS), Bangalore</li>
                    <li>Liquid Propulsion Systems Centre (LPSC), Valiamala</li>
                    <li>National Remote Sensing Centre (NRSC), Balanagar, Hyderabad, Telangana</li>
                    <li>Regional Remote Sensing Centre(RRSC), Kolkata</li>
                    <li>Space Applications Centre (SAC), Ahmedabad</li>
                    <li>Satish Dhawan Space Centre (SDSC) SHAR, Andhra Pradesh</li>
                    <li>Semi-Conductor Laboratory (SCL), Punjab</li>
                    <li>UR Rao Satellite Centre (URSC), Bengaluru</li>
                    <li>Vikram Sarabhai Space Centre (VSSC), Thiruvananthapuram</li>
                </ul>
           </Accordion.Body>
           </Accordion.Item>

        </Accordion>
        <br/>

        <Accordion>
           <Accordion.Item eventKey="ev1234">
           <Accordion.Header><b>Departments involved in carrying out R&D and Project Work at KCSTC: </b></Accordion.Header>
           <Accordion.Body>
                <ul>
                    <li>Aerospace Engineering </li>
                    <li>Chemical Engineering</li>
                    <li>Civil Engineering</li>
                    <li>Computer Science & Engineering</li>
                    <li>Centre for Ocean, Rivers, Atmosphere and Land Sciences(CORAL)</li>
                    <li>Cryogenic Engineering </li>
                    <li>Electrical Engineering </li>
                    <li>Electronics & Electrical Communication Engineering</li>
                    <li>G S Sanyal School of Telecommunications</li>
                    <li>Geology & Geophysics </li>
                    <li>Mathematics</li>
                    <li>Mechanical Engineering</li>
                    <li>Metallurgical & Materials Engineering</li>
                    <li>Mining Engineering</li>
                    <li>Ocean Engineering & Naval Architecture</li>
                    <li>Rubber Technology Centre</li>
                    <li>School of Medical Science & Technology</li>
                    <li>Subir Chowdhury School of Quality and Reliability (SCSQR)</li>
                </ul>
           </Accordion.Body>
           </Accordion.Item>

        </Accordion>
        </div>
        <br/><br/>
        </div>
     );
}
 
export default Jpcmembers;