import ima from "./assets/8.jpg"

const Members = () => {
  return (
    <div className= "container">
      <h4 className="center bold">People</h4>
      <br/>
      <h4 className="center bold"></h4>
      <div className= "row center">
      <div className="col-sm-3">
      <div className="card pad1">
      <img class="card-img-top" src="https://pbs.twimg.com/profile_images/1561969351418294272/3Zd3VM-R_400x400.jpg" alt="Card image cap" height = "325" width = "40"/>
      <div className="card-body">
      <h5 className="card-title">Prof. V. K. Tewari</h5>
      <p className="card-text"><b>Director, IIT Kharagpur</b></p>
      <a className="btn btn-primary" href="http://www.iitkgp.ac.in/director" target = "_blank">More info</a>
      </div>
      </div>
      </div>
      

      <div className="col-sm-3">
      <div className="card pad1">
      <img class="card-img-top" src= {ima} alt="Card image cap" height = "305" width = "35"/>
      <div className="card-body">
      <h5 className="card-title">Prof. Indrajit Chakrabarti</h5>
      <p className="card-text"><b>Chairman, Kalpana Chawla Space Technology Cell, IIT Kharagpur</b></p>
      <a className="btn btn-primary" href="http://www.ecdept.iitkgp.ac.in/Eece/facultydetails/ece-indrajit" target = "_blank">More info</a>
      </div>
      </div>
      </div>
      </div>
      <br/>
      <br/>
      
      <h4 className="center" style={{color: "red", fontWeight: 800}}>Office Staff</h4>
      <hr />

      <div className= "row center">
      <div className="col-sm-3">
      <div className="card pad1">
      {/* <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/800px-Elon_Musk_Royal_Society_%28crop2%29.jpg" alt="Card image cap" height = "400" width = "40"/> */}
      <div className="card-body">
      <h5 className="card-title">Mr. Sudha Ranjan Mishra</h5>
      <p className="card-text">SPA</p>
      {/* <a className="btn btn-primary" href="http://www.iitkgp.ac.in/director" target = "_blank">More info</a> */}
      </div>
      </div>
      </div>

      <div className="col-sm-3">
      <div className="card pad1">
      {/* <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/800px-Elon_Musk_Royal_Society_%28crop2%29.jpg" alt="Card image cap" height = "400" width = "40"/> */}
      <div className="card-body">
      <h5 className="card-title">Ms. Susmita Paine Gorai</h5>
      <p className="card-text">PA</p>
      {/* <a className="btn btn-primary" href="http://www.iitkgp.ac.in/director" target = "_blank">More info</a> */}
      </div>
      </div>
      </div>

      <div className="col-sm-3">
      <div className="card pad1">
      {/* <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/800px-Elon_Musk_Royal_Society_%28crop2%29.jpg" alt="Card image cap" height = "400" width = "40"/> */}
      <div className="card-body">
      <h5 className="card-title">Mr. Shiba Prasad Paria</h5>
      <p className="card-text">JPA</p>
      {/* <a className="btn btn-primary" href="http://www.iitkgp.ac.in/director" target = "_blank">More info</a> */}
      </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Members;