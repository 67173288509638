const Aboutus = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">About Us</div>
        <br />
        <h6>
        The activities of Space Technology Cell was initiated in 1998 in Indian Institute of Technology, Kharagpur with an aim to work in the areas of MEMS, Communications and Cryogenics including Engine Modeling. Subsequently the areas of materials, control systems, micro propulsions and VLSI design were included. The communications area extended to the areas of RF design, Electromagnetics, EMI/EMC and ESD. Starting with faculty members of the Departments of Aerospace Engineering, Chemical Engineering, Electronics & Electrical Communication Engineering, Electrical Engineering, Computer Science Engineering, Cryogenic Engineering, Mechanical Engineering, Metallurgical & Materials Engineering, Rubber Technology Centre, Advanced Technology Centre, G. S Sanyal School of Telecommunication, Mining Engineering and School of Medical Science and Technology.
        </h6>
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Aboutus;