const Vision = () => {
    return ( 
        <div className=" container" id="content">
        <div className = "containing2">
        <div className = "center bold">Vision</div>
        <br />
        <h6>
        <ul>
            <li>
            To promote research and applications in Space Science and Technology that compliments space agencies efforts.
            </li>
            <br/>
            <li>
            To promote human resource development and training in Space Science and Technology through specialized programmes.
            </li>
        </ul>
        
        </h6>
        </div>
        <br />
        <br />
        </div>
     );
}
 
export default Vision;