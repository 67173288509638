import useFetch from "./useFetch";
import Announcements from "./announcement";
import Post from "./post";
import Course from "./course";
import {API_URL} from "../constants";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Test = () => {
//   const { error, isPending, data: announcements } = useFetch(API_URL + '/api/announcements');
//   const { error: error1, isPending: isPending1, data: posts } = useFetch(API_URL + '/api/posts');
//   const { error: error2, isPending: isPending2, data: courses } = useFetch(API_URL + '/api/courses');
  const [announcements, setAnnouncements] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const [posts, setPosts] = useState(null);
  const [isPending1, setIsPending1] = useState(true);
  const [error1, setError1] = useState(null);

  const [courses, setCourses] = useState(null);
  const [isPending2, setIsPending2] = useState(true);
  const [error2, setError2] = useState(null);


  useEffect(() => {
    fetch(API_URL + '/api/announcements', {
    })
    .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        }
        return res.json();
      })
    .then(data => {
        setIsPending(false);
        setAnnouncements(data);
        setError(null);
        fetch(API_URL + '/api/posts', {
        })
        .then(res => {
            if (!res.ok) { // error coming back from server
              throw Error('could not fetch the data for that resource');
            }
            return res.json();
          })
        .then(data1 => {
            setIsPending1(false);
            setPosts(data1);
            setError1(null);
            fetch(API_URL + '/api/courses', {
            })
            .then(res => {
                if (!res.ok) { // error coming back from server
                  throw Error('could not fetch the data for that resource');
                }
                return res.json();
              })
            .then(data2 => {
                setIsPending2(false);
                setCourses(data2);
                setError2(null);
            })  
            .catch(err => {
              setIsPending2(false);
              setError2(err.message);
            })
        })  
        .catch(err => {
          setIsPending1(false);
          setError1(err.message);
        })
    })  
    .catch(err => {
      setIsPending(false);
      setError(err.message);
    })
}, [])

    return (
        <div className=" container" id="content">
          <br />
          <br />
            <div className="row row1 mx-n5">
            {/* <div className="col-sm-3 border1 scr">
                <div className="head">
                <br />
                <h4 className= "pad2 center" > <b>Recent Announcements</b></h4>
                <br />
                </div>
                { error && <div>{ error }</div> }
                { isPending && <div>Loading...</div> }
                { announcements && <Announcements announcements={announcements.slice(0, 5)} /> }
            <a href = "/announcements" className="right">See All Announcements</a>
            </div> */}
            <div className="col-sm-7 border1 scr">
                <div className="head">
                <br />
                <h4 className= "pad2 center" > <b>Activities</b></h4>
                </div>
                { error2 && <div>{ error2 }</div> }
                { isPending2 && <div>Loading...</div> }
                { courses && <Course courses={courses} /> }
            <Link to = "/activities" className="right">See All Activities</Link>
            </div>
            <div className="col-sm-3 border1 scr">
                <div className = "head center">
                <br />
                <h4> <b>Recent Announcements</b></h4>
                <br />
                </div>
                { error1 && <div>{ error1 }</div> }
                { isPending1 && <div>Loading...</div> }
                { posts && <Post posts={posts.slice(0, 5)} /> }
            <Link to = "/announcements" className="right">See All Announcements</Link>
            </div>
            </div>
            <br />
            <br />
        </div>
    );
}

export default Test;