import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import img1 from "../logo12.png"

const NavBar = () => {

  return (
    <nav className="navbar navbar-light navbar-expand-lg bgval hover-nav ho_navbar">
    <div className="container">
      <span className="navbar-brand"><img src={img1} alt="logo" width="355" height="60" className="d-inline-block align-text-top" />
      <span className="tex">&nbsp;</span> </span>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item navg">
            <Link className="nav-link active" aria-current="page" to={{pathname: '/', hash: '#'}}><span className="tex">Home</span></Link>
          </li>
          <li className="nav-item navg">
            <Link className="nav-link active" aria-current="page" to="/activities"><span className="tex">Activities</span></Link>
          </li>
          <li className="nav-item navg">
            {/* <Link className="nav-link active" aria-current="page" to="/announcements"><span className="tex">Announcements</span></Link> */}
          </li>
          <li className="nav-item navg">
            <Link className="nav-link active" aria-current="page" to="/announcements"><span className="tex">Announcements</span></Link>
          </li>
          <li className="nav-item navg">
            <Link className="nav-link active" aria-current="page" to="/res_areas"><span className="tex">Research Areas</span></Link>
          </li>
          <li className="nav-item navg">
            <Link className="nav-link active" aria-current="page" to="/facilities"><span className="tex">Facilities</span></Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
}
 
export default NavBar;