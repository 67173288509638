import { Link } from "react-router-dom";
import { Notyf } from "notyf";
import 'notyf/notyf.min.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {API_URL} from "../constants";
import { renderMarkup } from "react-render-markup";

function OnDelete(id, sub_id, subject_id)
{
    const notyf = new Notyf();
    const kcstc_token = localStorage.getItem("kcstc_token");
    fetch(API_URL + '/api/courses/sub/subject/' + id + '/' + sub_id + '/' + subject_id , {
    method: 'DELETE',
    headers: { "Authorization": kcstc_token }
    })
    .then((res) => {
        if(res.ok)
        {
            notyf.success("Entry deleted successfully!!!");
            setTimeout(()=>{
              window.location.reload();
            }, 700);
        }
        else notyf.error("Entry could not be deleted!");

    })
}

const submit = (id, sub_id, subject_id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this item ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => OnDelete(id, sub_id, subject_id)
        },
        {
          label: 'No'
        }
      ]
    });
  };

const Subject_indiv_admin = ( { course, class_id }) => {
    let class1;
    course.sub_course.forEach(cls => {
        if (cls._id == class_id) 
        {
             class1 = cls;
        }
    })
 
    return ( 
      <div className = "Individual">
          <div className = "head center bold">Details of {class1.Class} <br />
          <Link to ={ `/sub_create/${course._id}/${class_id}/1`}><button type="button" class="btn btn-warning" style ={{fontWeight:"bold"}}>Add Entry</button></Link>
          </div>
          <br />
          <h6><b>Description: </b> {renderMarkup(class1.description)}</h6>
          <div className = "tablestyle">
          <table className = "center5">
          <tr>
          <th>Sl. No.</th>
            <th>Title</th>
            {course.course_name.includes("ISRO") && <th>Collaborating Centre</th>}
            {course.course_name.includes("Sponsored") && <th>Sponsor</th>}
            {course.course_name.includes("Consult") && <th>Consultant</th>}
            <th>Duration</th>
            {course.course_name.includes("ISRO") && <th>PI/Co-PI</th>}
            {course.course_name.includes("Sponsored") && <th>Consultant</th>}
            {course.course_name.includes("Consult") && <th>Consultant</th>}
            {course.course_name.includes("Courses") && <th>Coordinator</th>}
          <th>Admin Control</th>
          <th>Admin Control</th>
          </tr>
          {class1.subjects.map((tab, index) => (
              <tr>
                <td>{index + 1}</td>
              <td>{tab.Title}</td>
                {course.course_name.includes("ISRO") && <td>{tab.Client}</td>}
                {course.course_name.includes("Sponsored") && <td>{tab.Client}</td>}
                {course.course_name.includes("Consult") && <td>{tab.Client}</td>}
              <td>{tab.Duration}</td>
              <td>{tab.Consultant}</td>
              <td><Link to={ `/sub_edit/${course._id}/${class_id}/${tab._id}`}><button type = "button" className = "btn btn-warning">Edit </button></Link></td>
              <td><button type = "button" className = "btn btn-danger" onClick={()=>{submit(course._id, class_id, tab._id)}}>Delete</button></td> 
              </tr>
          ))}
           </table>
           </div>
      </div>
   );
}
 
export default Subject_indiv_admin;