import useFetch from "./useFetch";
import { useParams } from "react-router-dom";
import Class_indiv from "./class_indiv";
import Course from "./course";
import Post from "./post";
import Post_course from "./Post_course";
import {API_URL} from "../constants";
import { useState, useEffect } from "react";

const Class = () => {
   const { id } = useParams();
//    console.log(id);
//     const { error: error2, isPending: isPending2, data: course } = useFetch(API_URL + '/api/courses/' + id);
//     if(!isPending2){
//     const { error, isPending, data: posts } = useFetch(API_URL + '/api/posts');
//     }

    const [course, setCourse] = useState(null);
    const [isPending2, setIsPending2] = useState(true);
    const [error2, setError2] = useState(null);

    const [posts, setPosts] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
    fetch(API_URL + '/api/courses/' + id, {
    })
    .then(res => {
        if (!res.ok) { // error coming back from server
            throw Error('could not fetch the data for that resource');
        }
        return res.json();
        })
    .then(data => {
        setIsPending2(false);
        setCourse(data);
        setError2(null);
        fetch(API_URL + '/api/posts', {
        })
        .then(res => {
            if (!res.ok) { // error coming back from server
                throw Error('could not fetch the data for that resource');
            }
            return res.json();
            })
        .then(data1 => {
            setIsPending(false);
            setPosts(data1);
            setError(null);
        })  
        .catch(err => {
            setIsPending(false);
            setError(err.message);
        })
    })  
    .catch(err => {
        setIsPending2(false);
        setError2(err.message);
    })
    }, [])
    return ( 
        <div className=" container">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 scr2">
                { error2 && <div>{ error2 }</div> }
                { isPending2 && <div>Loading...</div> }
                { course && <div className = "head center bold">All sections under {course.course_name} </div>}
                <br />
                { error2 && <div>{ error2 }</div> }
                { isPending2 && <div>Loading...</div> }
                { course && <Class_indiv course = {course} />}
                <br />
                <br />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 scr2">
                { error2 && <div>{ error2 }</div> }
                { isPending2 && <div>Loading...</div> }
                { course && <div className = "head center bold">All Announcements under {course.course_name} </div>}
                <br />
                { error && <div>{ error }</div> }
                { isPending && <div>Loading...</div> }
                { posts && <Post_course posts= { posts } course = {course} />}
                </div>
            </div>
        </div>
     );
}
 
export default Class;